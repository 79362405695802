.grid-table .thead .th {
    border-right: 1px solid #708595;
}

.grid-table .thead .th:last-child {
    border-right: none;
}

.row:nth-child(odd) {
    background-color: #d0d9df;
}
